// External packages
import { graphql } from "gatsby"
import React from "react"
import styled from "@emotion/styled"

// Components
import Layout from "../components/layouts"
import ContentModules from "../components/Page/ContentModules"
import SEO from "../components/seo"
import { ShopTheLook } from "../components/v2/ShopTheLook"

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.grayscale[100]};

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    padding-top: 50px;
  }
`

const PageTemplate = ({ data, pageContext }) => {

  let { page } = data
  let shopTheLookItems = pageContext.shopTheLookModules

  return (
    <Layout
      pullUp={page.isNavbarOverlapping}
      hidePromotional={page.hidePromotionalElement}
      isSizeGuide={page.pageTitle === "Size Guide" ? true : false}
    >
      <SEO
        metaImage={page.metaImage && page.metaImage.file.url}
        title={page.pageTitle}
        description={page.metaDescription?.metaDescription}
        canonicalUrl={`/${pageContext.slug}`}
      />
      <Container>
        {page.contentModules?.length &&
          shopTheLookItems?.length > 0 ? (
          <ShopTheLook items={shopTheLookItems} />
        ) : (
          <ContentModules
            pullUp={page.isNavbarOverlapping}
            allModules={page.contentModules}
            shopstoryContent={pageContext.shopstoryContent}
          />
        )}
      </Container>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PageQuery($slug: String!, $locale: String) {
    page: contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      ...PageContent
    }
  }
`
